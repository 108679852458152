import { reactive } from 'vue'
import { table_one } from '@/plugins/table_one'
import { table_two } from '@/plugins/table_two'
import { table_three } from '@/plugins/table_three'
import { table_four } from '@/plugins/table_four'

export const Main_one = reactive({
  JJtype: 'first',
  kfs: 'second',
  cnjj: 'first',
  jjph: 'second',
  activeIndex: '1',
  C_activeIndex: '1',
  is_display: true,
  Select_time: '',
  toKFSjjjz: function (key,Axios) {
    if (key === '1-1') {
      this.is_display = true
      this.JJtype = 'first'
      this.kfs = 'second'
      table_one.form.FundType = '全部'
      table_one.form.ObservationDate = this.now_time()
      table_one.getKFSdata(Axios)
    } else if (key === '1-2') {
      this.is_display = true
      this.JJtype = 'second'
      table_two.form.ObservationDate = this.now_time()
      table_two.getHBXData(Axios)
    } else if (key === '1-3') {
      this.is_display = true
      this.JJtype = 'third'
      table_three.form.FundType = '场内交易基金'
      table_three.form.ObservationDate = this.now_time()
      table_three.getCNJYData(Axios)
    } else if (key === '1-4') {
      this.is_display = false
      this.JJtype = 'fourth'
      table_four.form.FundType = '全部'
      table_four.getFPRdata(Axios)
    }
  },
  now_time () {
    const currentDate = new Date()
    return currentDate.toISOString().slice(0, 10).replace(/-/g, '-')
  },
  ChoiceFundType (Axios) {
    if (Main_one.JJtype === 'first') {
      table_one.form.FundType = '全部'
      table_one.form.ObservationDate = this.now_time()
      table_one.getKFSdata(Axios)
    } else if (Main_one.JJtype === 'second') {
      table_two.form.ObservationDate = this.now_time()
      table_two.getHBXData(Axios)
    } else if (Main_one.JJtype === 'third') {
      table_three.form.FundType = '场内交易基金'
      table_three.form.ObservationDate = this.now_time()
      table_three.getCNJYData(Axios)
    } else if (Main_one.JJtype === 'fourth') {
      table_four.form.FundType = '全部'
      table_four.getFPRdata(Axios)
    }
  }
})
