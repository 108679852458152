import { reactive, ref } from 'vue'
import { Main_one } from '@/plugins/Main_one'

// eslint-disable-next-line camelcase
export const table_one = reactive({
  OpenEndedFundInfo: [{
    fundCode: '',
    fundName: '',
    netAssetValuePerUnit: '',
    accumulatedNetValue: '',
    purchaseStatus: '',
    redemptionStatus: '',
    dailyIncreaseValue: '',
    dailyGrowthRate: '',
    handlingFee: '',
    observationDate: ''
  }],
  form: {
    PageNum: 1,
    ObservationDate: '',
    Form_length: '',
    FundType: ''
  },
  Table_total: 0,
  async changeTableData (Axios) {
    this.form.ObservationDate = Main_one.Select_time
    this.form.PageNum = 1
    if (Main_one.kfs === 'second') {
      this.form.FundType = '全部'
    } else if (Main_one.kfs === 'third') {
      this.form.FundType = '指数型'
    } else if (Main_one.kfs === 'fourth') {
      this.form.FundType = '股票型'
    } else if (Main_one.kfs === 'fifth') {
      this.form.FundType = '混合型'
    } else if (Main_one.kfs === 'sixth') {
      this.form.FundType = '债券型'
    } else if (Main_one.kfs === 'seventh') {
      this.form.FundType = 'QDII'
    } else if (Main_one.kfs === 'eighth') {
      this.form.FundType = 'FOF'
    }
    const list = await Axios.post('http://120.46.35.21:8081/FundData/GetOEF', { ...this.form })
    this.OpenEndedFundInfo = list.data.list
    this.Table_total = list.data.total
  },
  ChangeTime (formattedDateLocalCustom, Axios) {
    Main_one.Select_time = formattedDateLocalCustom
    table_one.form.ObservationDate = formattedDateLocalCustom
    this.form.PageNum = 1
    table_one.changeTableData(Axios)
  },
  async getKFSdata (Axios) {
    let currentDate = this.form.ObservationDate
    while (true) {
      try {
        const response = await Axios.post('http://120.46.35.21:8081/FundData/GetOEF', {
          ...this.form,
          ObservationDate: currentDate
        })
        const list = response.data.list
        if (list.length > 0) {
          this.OpenEndedFundInfo = list
          Main_one.Select_time = currentDate
          this.form.Form_length = list.length.toString()
          this.Table_total = response.data.total
          break
        } else {
          const observationDate = new Date(currentDate)
          observationDate.setDate(observationDate.getDate() - 1)
          currentDate = observationDate.toISOString().split('T')[0]
        }
      } catch (error) {
        console.error(error)
        break
      }
    }
  }

})
