import { reactive } from 'vue'

export const PersonalInformation = reactive({
  Userid: '',
  loading_m: false,
  loading_l: true,
  username: '',
  isLoginExpired () {
    // 从sessionStorage中读取并解析存储的userInfo对象
    const storedUserInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    // 如果没有存储的userInfo，返回true表示过期
    if (!storedUserInfo) {
      this.loading_l = true
      this.loading_m = false
      return true
    }
    // 计算半小时前的时间戳（单位为毫秒）
    const halfHourAgoTimestamp = new Date().getTime() - (30 * 60 * 1000)
    if (storedUserInfo.loginTime < halfHourAgoTimestamp) {
      this.loading_l = true
      this.loading_m = false
      this.username = ''
    }
    // 检查登录时间是否在过去半小时内
    return storedUserInfo.loginTime < halfHourAgoTimestamp
  },
  // 从sessionStorage中读取并解析存储的userInfo对象
  getUserInfo () {
    const storedUserInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    this.Userid = storedUserInfo.userId
  },
  setUserInfo () {
    if (!PersonalInformation.isLoginExpired()){
      const loginmessage = JSON.parse(sessionStorage.getItem('loginmessage'))
      PersonalInformation.loading_l = loginmessage.loading_l
      PersonalInformation.loading_m = loginmessage.loading_m
      PersonalInformation.username = loginmessage.username
    }
  }
})
