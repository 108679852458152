import { reactive } from 'vue'
import { Main_one } from '@/plugins/Main_one'

export const table_two = reactive({
  MoneyMarketFundDetails: [{
    fundCode: '',
    fundName: '',
    observationDate: '',
    tenThousandShareIncome: '',
    sevenDayAnnualizedRate: '',
    netAssetValue: '',
    dailyGrowthRate: '',
    establishmentDate: '',
    managerName: '',
    handlingFee: ''
  }],
  form: {
    PageNum: 1,
    ObservationDate: '',
    Form_length: '',
    FundType: ''
  },
  Table_total: 0,
  async getHBXData (Axios) {
    let currentDate = this.form.ObservationDate
    while (true) {
      try {
        const response = await Axios.post('http://120.46.35.21:8081/FundData/GetMMF', {
          ...this.form,
          ObservationDate: currentDate
        })
        const list = response.data.list
        if (list.length > 0) {
          this.MoneyMarketFundDetails = list
          Main_one.Select_time = currentDate
          this.form.Form_length = list.length.toString()
          this.Table_total = response.data.total
          break
        } else {
          const observationDate = new Date(currentDate)
          observationDate.setDate(observationDate.getDate() - 1)
          currentDate = observationDate.toISOString().split('T')[0]
        }
      } catch (error) {
        console.error(error)
        break
      }
    }
  },
  async ChangeTime (formattedDateLocalCustom, Axios) {
    Main_one.Select_time = formattedDateLocalCustom
    table_two.form.ObservationDate = formattedDateLocalCustom
    this.form.PageNum = 1
    const currentDate = this.form.ObservationDate
    const response = await Axios.post('http://120.46.35.21:8081/FundData/GetMMF', {
      ...this.form,
      ObservationDate: currentDate
    })
    const list = response.data.list
    this.MoneyMarketFundDetails = list
    Main_one.Select_time = currentDate
    this.form.Form_length = list.length.toString()
    this.Table_total = response.data.total
  }
})
