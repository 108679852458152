import { reactive } from 'vue'

export const SerachPage = reactive({
  Fund: {
    fundCode: '',
    fundName: '',
    netAssetValuePerUnit: '',
    accumulatedNetValue: '',
    oneMonthGrowthRate: '',
    threeMonthsGrowthRate: '',
    sixMonthsGrowthRate: '',
    oneYearGrowthRate: '',
    twoYearsGrowthRate: '',
    threeYearsGrowthRate: '',
    thisYearGrowthRate: '',
    sinceInceptionGrowthRate: '',
    handlingFee: ''
  },
  form: {
    searchItem: ''
  },
  input: '',
  async GetSearchData (Axios) {
    const requestData = await Axios.post('http://120.46.35.21:8081/GetSearch', { ...this.form })
    return requestData.data[0]
  }
})
