import { reactive } from 'vue'
import { Main_one } from '@/plugins/Main_one'

export const table_three = reactive({
  ListedFundData: [{
    fundCode: '',
    fundName: '',
    observationDate: '',
    netAssetValuePerUnit: '',
    accumulatedNetValue: '',
    growthAmount: '',
    growthRate: '',
    marketPrice: '',
    discountRate: ''
  }],
  form: {
    PageNum: 1,
    ObservationDate: '',
    Form_length: '',
    FundType: ''
  },
  Table_total: 0,
  async getCNJYData (Axios) {
    let currentDate = this.form.ObservationDate
    while (true) {
      try {
        const response = await Axios.post('http://120.46.35.21:8081/FundData/GetLFD', {
          ...this.form,
          ObservationDate: currentDate
        })
        const list = response.data.list
        if (list.length > 0) {
          this.ListedFundData = list
          Main_one.Select_time = currentDate
          this.form.Form_length = list.length.toString()
          this.Table_total = response.data.total
          break
        } else {
          const observationDate = new Date(currentDate)
          observationDate.setDate(observationDate.getDate() - 1)
          currentDate = observationDate.toISOString().split('T')[0]
        }
      } catch (error) {
        console.error(error)
        break
      }
    }
  },
  async ChangeTime (formattedDateLocalCustom, Axios) {
    Main_one.Select_time = formattedDateLocalCustom
    table_three.form.ObservationDate = formattedDateLocalCustom
    this.form.PageNum = 1
    const currentDate = this.form.ObservationDate
    const response = await Axios.post('http://120.46.35.21:8081/FundData/GetLFD', {
      ...this.form,
      ObservationDate: currentDate
    })
    const list = response.data.list
    this.ListedFundData = list
    Main_one.Select_time = currentDate
    this.form.Form_length = list.length.toString()
    this.Table_total = response.data.total
  },
  async changeTableData (key, Axios) {
    this.form.ObservationDate = Main_one.Select_time
    this.form.PageNum = 1
    if (key === 'first') {
      this.form.FundType = '场内交易基金'
    } else if (key === 'second') {
      this.form.FundType = 'ETF'
    }
    const list = await Axios.post('http://120.46.35.21:8081/FundData/GetLFD', { ...this.form })
    this.ListedFundData = list.data.list
    this.Table_total = list.data.total
  }
})
