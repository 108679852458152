import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/components/HomePage.vue')
  },
  {
    path: '/Search/:id',
    name: 'Search',
    component: () => import('@/components/SearchPage.vue')
  },
  {
    path: '/JJData',
    name: 'JJData',
    component: () => import('@/components/JJData.vue')
  },
  {
    path: '/Login',
    name: 'Login',
    component: () => import('@/components/Login.vue')
  },
  {
    path: '/Register',
    name: 'Register',
    component: () => import('@/components/Register.vue')
  },
  {
    path: '/FundInformation/:title',
    name: 'FundInformation',
    component: () => import('@/components/JJZX.vue')
  },
  {
    path: '/news/:id',
    name: 'News',
    component: () => import('@/components/News.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/404.vue')
  },
  {
    path: '/PersonalPage',
    name: 'PersonalPage',
    children: [
      {
        path: '/PersonalPage/FundCollection',
        name: 'FundCollection',
        component: () => import('@/views/FundCollection.vue')
      },
      {
        path: '/PersonalPage/ArticleCollection',
        name: 'ArticleCollection',
        component: () => import('@/views/ArticleCollection.vue')
      },
      {
        path: '/PersonalPage/AccountInformation',
        name: 'AccountInformation',
        component: () => import('@/views/AccountInformation.vue')
      },
      {
        path: '/PersonalPage/ChatRoom',
        name: 'ChatRoom',
        component: () => import('@/views/ChatAi.vue')
      },
      {
        path: '/PersonalPage/questionnaire',
        name: 'questionnaire',
        component: () => import('@/views/questionnaire.vue')
      }
    ],
    component: () => import('@/components/CollectionsPage.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
