<script setup>
import { Main_one } from '@/plugins/Main_one'
</script>
<template >
    <div style=" width: 1057px;margin: auto">
      <el-menu :default-active="Main_one.activeIndex" @select="GethandleSelect" class="el-menu-demo row" style="text-align: center" mode="horizontal" >
        <el-submenu index="1" class="col-4">
          <template v-slot:title>基金数据</template>
          <el-menu-item index="1-1">开放式基金净值</el-menu-item>
          <el-menu-item index="1-2">货币型基金收益</el-menu-item>
          <el-menu-item index="1-3">场内交易基金净值</el-menu-item>
          <el-menu-item index="1-4">基金排行</el-menu-item>
        </el-submenu>
        <el-submenu index="3" class="col-4">
          <template v-slot:title>基金资讯</template>
          <el-menu-item index="3-1">基金要闻</el-menu-item>
          <el-menu-item index="3-2">基金策略</el-menu-item>
          <el-menu-item index="3-3">基金观点</el-menu-item>
        </el-submenu>
        <el-submenu index="4" class="col-4">
          <template v-slot:title>我的基金</template>
          <el-menu-item index="4-1">基金收藏</el-menu-item>
          <el-menu-item index="4-2">文章收藏</el-menu-item>
          <el-menu-item index="4-3">账号信息</el-menu-item>
          <el-menu-item index="4-4">基金咨询</el-menu-item>
          <el-menu-item index="4-5">投资性格</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
</template>
<script >

import { Main_one } from '@/plugins/Main_one'
import { News } from '@/plugins/News'

export default {
  data () {
    return {
      activeIndex: '1'
    }
  },
  methods: {
     async GethandleSelect (key, keyPath) {
       if (keyPath[0] === '1') {
         this.$router.push('/JJData')
         Main_one.toKFSjjjz(key, this.axios)
       }

       if (key === '3-1') {
         this.$router.push('/FundInformation/基金要闻')
         News.NewsMessage.newstype = '基金要闻'
         News.NewsList = await News.GetNewsList(this.axios)
       } else if (key === '3-2') {
         this.$router.push('/FundInformation/基金策略')
         News.NewsMessage.newstype = '基金策略'
         News.NewsList = await News.GetNewsList(this.axios)
       } else if (key === '3-3') {
         this.$router.push('/FundInformation/基金观点')
         News.NewsMessage.newstype = '基金观点'
         News.NewsList = await News.GetNewsList(this.axios)
       }
       if (key === '4-1') {
         Main_one.C_activeIndex = '1'
         this.$router.push('/PersonalPage/FundCollection')
       } else if (key === '4-2') {
         Main_one.C_activeIndex = '2'
         this.$router.push('/PersonalPage/ArticleCollection')
       } else if (key === '4-3') {
         Main_one.C_activeIndex = '3'
         this.$router.push('/PersonalPage/AccountInformation')
       } else if (key === '4-4') {
         Main_one.C_activeIndex = '4'
         this.$router.push('/PersonalPage/ChatRoom')
       } else if (key === '4-5') {
         Main_one.C_activeIndex = '5'
         this.$router.push('/PersonalPage/questionnaire')
       }
     }
  }
}

</script>
