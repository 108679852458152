<script setup>
import { SerachPage } from '@/plugins/SearchPage'
import { PersonalInformation } from '@/plugins/PersonalInformation'
</script>
<template>
  <div class="top">
    <div class="container mt-2">
      <div class="row">
        <div class="datetime col-2"></div>
        <div class="col-1">
          <el-button type="primary" @click="index">首页</el-button>
        </div>
        <div class="col-6"></div>
        <div class="col-2 text-end"></div>
        <div class="col-1">
          <el-button v-if="PersonalInformation.loading_l" type="primary" @click="Login">登录/注册</el-button>
          <el-menu v-if="PersonalInformation.loading_m" @select="GethandleSelect" class="el-menu-demo row" style="text-align: center;height: 20px"
                   mode="horizontal"
                   background-color="#409EFF"
                   active-text-color="#fff"
                   text-color="#fff">
            <el-submenu>
              <template v-slot:title>{{ PersonalInformation.username }}</template>
              <el-menu-item index="1" >退出登录</el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
      </div>
    </div>
    <div class="row mt-3" style="width: 70%;margin: auto">
      <div class="col-2 text-center"><img src="../assets/2.png" style="height: 50px;"></div>
      <div class="col-7">
        <el-input v-model="SerachPage.input" placeholder="请输入基金代码"></el-input>
      </div>
      <div class="col-1 ">
        <el-button type="primary" icon="el-icon-search" @click="searcha">搜索</el-button>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
</template>
<style>
.top {
  background: #409EFF;
  padding: 0 0 8px 0;
}
</style>
<script>

import { SerachPage } from '@/plugins/SearchPage'
import { PersonalInformation } from '@/plugins/PersonalInformation'
export default {
  data () {
    return {
      loading_l: true,
      loading_m: false,
      username: ''
    }
  },
  mounted () {
    this.getDateTime()
    setInterval(this.getDateTime, 1000)
  },
  methods: {
    index () {
      this.$router.push('/')
    },
    Login () {
      this.$router.push('/Login')
    },
    getDateTime () {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()
      const ymd = year + '年' + month + '月' + day + '日'
      const hms = hour + ':' + minute + ':' + second
      document.querySelector('.datetime').style.color = 'white'
      document.querySelector('.datetime').innerText = ymd + ' ' + hms
    },
    searcha () {
      if (SerachPage.input === '') {
        this.$message({
          message: '请输入基金代码或简称',
          type: 'warning'
        })
      } else {
        SerachPage.form.searchItem = SerachPage.input
        const data = SerachPage.GetSearchData(this.axios)
        console.log(data)
        data.then(async result => {
          if (result === undefined) {
            this.$message({
              message: '未找到该基金',
              type: 'warning'
            })
          } else {
            SerachPage.Fund = await data
            this.$router.push('/Search/' + SerachPage.form.searchItem)
          }
          SerachPage.input = ''
        })
      }
    },
    GethandleSelect (index) {
      if (index === '1') {
        PersonalInformation.Userid = ''
        PersonalInformation.username = ''
        PersonalInformation.loading_l = true
        PersonalInformation.loading_m = false
        sessionStorage.removeItem('loginmessage')
        sessionStorage.removeItem('userInfo')
        this.$message.success('退出成功')
      }
    }
  },
  created () {
    if (! PersonalInformation.isLoginExpired()){
      const loginmessage = JSON.parse(sessionStorage.getItem('loginmessage'))
      PersonalInformation.loading_l = loginmessage.loading_l
      PersonalInformation.loading_m = loginmessage.loading_m
      PersonalInformation.username = loginmessage.username
    }
  }
}
</script>
