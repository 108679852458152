import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element.js'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import elTableInfiniteScroll from 'el-table-infinite-scroll'
const app = createApp(App)
app.use(VueAxios, axios)
installElementPlus(app)
app.use(store).use(router).mount('#app')
app.use(elTableInfiniteScroll)
