import { reactive } from 'vue'

export const News = reactive({
  NewsDetail: {
    id: '',
    title: '',
    content: ''
  },
  NewsMessage: {
    id: '',
    newstype: '',
    pageNum: 1,
    pagetol: ''
  },
  async GetNewsList (Axios) {
    const List = await Axios.post('http://120.46.35.21:8081/GetNewsList', { ...this.NewsMessage })
    const data = List.data
    this.NewsMessage.pagetol = data.pages
    const NewList = []
    for (let i = 0; i < data.list.length; i++) {
      NewList.push({
        newsid: data.list[i].newsid,
        title: data.list[i].title,
      })
    }
    return NewList
  }
})
