<script setup>
import Top from '@/views/Top.vue'
import Bottom from '@/views/Bottom.vue'
import Main_one from '@/views/Main_one.vue'
</script>
<template>
  <Top v-show="!(path === '/Login' || path==='/404' || path === '/Register')" />
  <Main_one v-show="!(path === '/Login' || path==='/404' || path === '/Register')"/>
    <router-view>
    </router-view>
  <Bottom v-show="!(path === '/Login' || path==='/404' || path === '/Register')" />
</template>
<script>
export default {
  data () {
    return {
      path: ''
    }
  },
  mounted () {
    this.body_css()
    console.log(this.$route.path)
    this.path = this.$route.path
  },
  methods: {
    body_css () {
      document.body.style.background = '#e3e6ec'
      document.body.style.margin = '0'
    }
  },
  watch: {
    $route (to, from) {
      this.path = to.path
    }
  }
}
</script>
